import { styled, Checkbox } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',

  '& > label': {
    color: '#000000',
    marginLeft: '0.5rem'
  }
});

export const StyledCheckbox = styled(Checkbox)(() => ({
  '&.MuiCheckbox-root': {
    padding: 0,

    '&.Mui-checked': {
      color: '#263B54'
    }
  }
}));
