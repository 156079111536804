import { styled } from '@mui/material';
import { Dialog } from 'presentation/components/Dialog/Dialog';
import { ModelTypeSelector } from 'presentation/components/ModelTypeSelector/ModelTypeSelector';

export const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    width: 'fit-content',
    maxWidth: 'unset'
  }
});

export const Container = styled('div')({
  display: 'flex',
  gap: '1rem',
  flexDirection: 'column',

  '& > button': {
    width: '100%'
  }
});

export const Details = styled('span')({
  maxWidth: '565px',
  fontSize: '14px',
  color: '#60646C'
});

export const StyledModelTypeSelector = styled(ModelTypeSelector)(({ theme }) => ({
  '& .options-wrapper': {
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap'
    }
  }
}));
