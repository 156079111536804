import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  CompanyName,
  Container,
  Header,
  Notification,
  NotificationBadge,
  Sections,
  Timestamp,
  Wrapper
} from './UserNotifications.styles';
import { BellIcon, CheckCircleOutlined, CloseIcon } from '../Icons';
import { useStatsigClient } from '@statsig/react-bindings';
import { useSelectors, useUserServices } from 'presentation/hooks';
import { NOTIFICATION_POLLING_DELAY } from 'main/constants';
import { Portal } from '../Portal/Portal';
import { ClickAwayListener } from '../ClickAwayListener/ClickAwayListener';
import { routes } from 'main/router';
import { generatePath } from 'react-router';
import { Button } from '@mui/material';
import { Tooltip } from '../Tooltip/Tooltip';
import { Mixpanel } from 'infra/mixpanel';

type Props = {
  expanded?: boolean;
};

export const UserNotifications = ({ expanded }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const statsig = useStatsigClient().client;
  const { isAuthenticated } = useSelectors.Auth();

  const [open, setOpen] = useState(false);

  const notificationsEnabled = useMemo(
    () => isAuthenticated && statsig.checkGate('earnings_notification_enabled'),
    [isAuthenticated, statsig]
  );

  const { data } = useUserServices().getNotifications({
    skip: !notificationsEnabled,
    pollInterval: NOTIFICATION_POLLING_DELAY,
    onError(error) {
      console.error('[Marketplace] User Notifications - Failed to fetch notifications', error);
    }
  });
  const [markSeenNotifications] = useUserServices().markSeenNotifications();

  const notificationsCount = useMemo(() => {
    const count = data?.earningUpdateNotifications?.filter(({ seen }) => !seen).length ?? 0;

    if (!count) return;

    return count > 999 ? '999+' : count;
  }, [data?.earningUpdateNotifications]);

  const handleOpen = () => {
    setOpen(true);
    Mixpanel.track('marketplaceV2:menu:notification_bell_icon:click');
  };

  const handleClose = () => {
    setOpen(false);
    Mixpanel.track('marketplaceV2:menu:notifications:close');
  };

  const formatDate = useCallback((dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    return date.toLocaleString('en-US', options);
  }, []);

  const handleUpdateClick = useCallback(
    (company: { id: number; name: string; ticker: string }) => () => {
      void markSeenNotifications({ variables: { companyIds: [company.id.toString()] } });
      Mixpanel.track('marketplace:notifications:item:click', {
        item: company
      });
    },
    [markSeenNotifications]
  );

  const handleMarkAllSeen = useCallback(() => {
    if (!data?.earningUpdateNotifications) return;

    const companyIds = data?.earningUpdateNotifications.map(({ company }) => company.id.toString());
    void markSeenNotifications({ variables: { companyIds } });
    Mixpanel.track('marketplace:notifications:mark_all_as_read:click', {
      companyIds
    });
  }, [data?.earningUpdateNotifications, markSeenNotifications]);

  if (!notificationsEnabled) return <></>;

  return (
    <>
      <Tooltip title="Alerts">
        <Button className="menu-button" onClick={handleOpen}>
          <BellIcon />
          {expanded && <div>Alerts</div>}
          {!!notificationsCount && (
            <NotificationBadge className="notifications-badge">
              {notificationsCount}
            </NotificationBadge>
          )}
        </Button>
      </Tooltip>
      {open && (
        <Portal>
          <ClickAwayListener onClickAway={handleClose}>
            <Container ref={containerRef}>
              <Header>
                Alerts
                <Button onClick={handleMarkAllSeen} color="primary">
                  <CheckCircleOutlined /> Mark all as read
                </Button>
                <CloseIcon onClick={handleClose} />
              </Header>
              <Wrapper>
                {data?.earningUpdateNotifications.map(
                  ({ company, notificationCount, seen, updatedAt }) => (
                    <Notification key={company.id}>
                      <Tooltip title={company.name}>
                        <CompanyName>{company.name}</CompanyName>
                      </Tooltip>
                      <Tooltip title={''}>
                        <Sections></Sections>
                      </Tooltip>
                      <Tooltip title={formatDate(updatedAt)}>
                        <Timestamp>{formatDate(updatedAt)}</Timestamp>
                      </Tooltip>
                      <a
                        href={generatePath(routes.LIVE_PREVIEW, { companyId: company.id })}
                        target="_blank"
                        rel="noreferrer"
                        onClick={handleUpdateClick(company)}
                      >
                        {notificationCount} updates
                      </a>
                      {!seen && <div className="badge" />}
                    </Notification>
                  )
                )}
              </Wrapper>
            </Container>
          </ClickAwayListener>
        </Portal>
      )}
    </>
  );
};
