import React from 'react';
import { CheckboxProps } from '@mui/material';
import { Container, StyledCheckbox } from './Checkbox.styles';

type Props = CheckboxProps & {
  label?: string;
};

export const Checkbox = ({ className, label, ...rest }: Props) => {
  return (
    <Container className={className}>
      <StyledCheckbox {...rest} />
      <label>{label}</label>
    </Container>
  );
};
