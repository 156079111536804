import { gql } from '@apollo/client';
import { gqlClient } from 'infra/http';
import { getLocalValue } from 'localization';
import { put, takeLatest } from 'redux-saga/effects';
import { selectState } from 'store/helpers';
import { Mixpanel } from 'infra/mixpanel';
import actions from './actions';
import modelsSelectors from './selector';
import actionTypes, {
  fundamental,
  getModelAction,
  getModelFromIndustryAction,
  getUpdatedFundamentalGroupsAction,
  getUpdatedFundamentalsAction,
  modelObject,
  unSubscribeAction,
  updatedFundamentalGroup
} from './types';

export function* getSectorSaga() {
  yield put(actions.getSectorsPending());
  try {
    const {
      data: { sectors }
    } = yield gqlClient.query({
      query: gql`
        query getSectors {
          sectors {
            totalNumberOfModels
            sectorList {
              name
              modelCount
              id
              industriesSet {
                id
                name
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-first'
    });
    yield put(actions.setSectors(sectors));
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.getSectorsError(errorMsg));
  }
}

export function* getModelSaga(action: ReturnType<getModelAction>) {
  const {
    params: { isUpdate, ...rest }
  } = action;

  Mixpanel.track('marketplace:ModelSearch', rest);
  if (isUpdate) {
    yield put(actions.updateModelsPending());
  } else {
    yield put(actions.getModelsPending());
  }

  try {
    const {
      data: {
        paginatedClientModelsV2: { modelsByIndustry, totalNumberOfIndustries }
      }
    } = yield gqlClient.query({
      query: gql`
        query initializeStatesQuery(
          $sectorId: Int
          $numberOfIndustries: Int
          $numberOfModelsPerIndustry: Int
          $keyword: String
          $pageNumber: Int
        ) {
          paginatedClientModelsV2(
            sectorId: $sectorId
            numberOfIndustries: $numberOfIndustries
            numberOfModelsPerIndustry: $numberOfModelsPerIndustry
            keyword: $keyword
            pageNumber: $pageNumber
          ) {
            totalNumberOfIndustries
            modelsByIndustry {
              industry {
                id
                name
              }
              numberOfModelsInIndustry
              clientModels {
                id
                downloadUrl
                latestQuarter
                isSubscribed
                company {
                  id
                  name
                  ticker
                  disabled
                  favicon
                  priority
                }
              }
            }
          }
        }
      `,
      variables: rest,
      fetchPolicy: 'no-cache'
    });
    if (isUpdate) {
      yield put(actions.updateModels(modelsByIndustry));
    } else {
      yield put(actions.setModels(modelsByIndustry, totalNumberOfIndustries));
    }
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.getModelsError(errorMsg));
  }
}

function* getModelFromIndustrySaga({
  industryId,
  indexToInsert
}: ReturnType<getModelFromIndustryAction>) {
  yield put(actions.getModelsFromIndustryPending(industryId));
  try {
    const {
      data: { excelModelsForIndustry }
    } = yield gqlClient.query({
      query: gql`
        query excelModelsForIndustryQuery($industryId: Int!) {
          excelModelsForIndustry(industryId: $industryId) {
            id
            downloadUrl
            latestQuarter
            isSubscribed
            company {
              id
              name
              ticker
              favicon
              priority
              disabled
            }
          }
        }
      `,
      variables: { industryId },
      fetchPolicy: 'cache-first'
    });

    let modelList: modelObject[] | null = yield selectState<modelObject[] | null>(
      modelsSelectors.getModelsList
    );
    if (modelList) {
      modelList = modelList.map((value, index) =>
        index !== indexToInsert
          ? { ...value }
          : {
              ...value,
              clientModels: excelModelsForIndustry ?? null
            }
      );
    }

    yield put(actions.setModelsFromIndustry(modelList));
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.getModelsError(errorMsg));
  }
}

function* getMyModelSaga() {
  yield put(actions.getModelsPending());

  try {
    const {
      data: { myModels }
    } = yield gqlClient.query({
      query: gql`
        query getMyModels {
          myModels {
            id
            downloadUrl
            latestQuarter
            isSubscribed
            company {
              id
              name
              ticker
              favicon
            }
          }
        }
      `,
      fetchPolicy: 'no-cache'
    });

    yield put(actions.setMyModels(myModels));
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.getModelsError(errorMsg));
  }
}

function* unSubscribeModelSaga({ companyId }: ReturnType<unSubscribeAction>) {
  yield put(actions.unSubscribeModelPending());

  try {
    const {
      data: {
        unsubscribeModel: { success, errors }
      }
    } = yield gqlClient.mutate({
      mutation: gql`
        mutation unsubscribeModelMutation($companyId: String!) {
          unsubscribeModel(companyId: $companyId) {
            success
            errors
          }
        }
      `,
      variables: { companyId },
      fetchPolicy: 'no-cache'
    });

    if (success) {
      yield put(actions.unSubscribeModelSuccess());
    } else {
      yield put(actions.unSubscribeModelError(errors[0]));
    }
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.unSubscribeModelError(errorMsg));
  }
}

function* getUpdatedFundamentalGroupSaga({
  companyId
}: ReturnType<getUpdatedFundamentalGroupsAction>) {
  yield put(actions.getUpdatedFundamentalGroupsPending());
  try {
    const {
      data: { updatedFundamentalGroups }
    } = yield gqlClient.query({
      query: gql`
        query updatedFundamentalGroupsQuery($companyId: String!) {
          updatedFundamentalGroups(companyId: $companyId) {
            fundamentalGroups {
              id
              label
              updatedAt
              earningsUpdatedAt
            }
            document {
              id
              filingType
              documentType
              dropTime
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        companyId
      }
    });
    yield put(
      actions.getUpdatedFundamentalGroupsSuccess(
        updatedFundamentalGroups as updatedFundamentalGroup
      )
    );
  } catch (error) {
    yield put(actions.getUpdatedFundamentalGroupsError());
  }
}

function* getUpdatedFundamentalsSaga({
  companyId,
  groupId
}: ReturnType<getUpdatedFundamentalsAction>) {
  yield put(actions.getUpdatedFundamentalsPending(groupId));
  try {
    const {
      data: { updatedFundamentals }
    } = yield gqlClient.query({
      query: gql`
        query updatedFundamentalsQuery($companyId: String!, $groupId: String!) {
          updatedFundamentals(companyId: $companyId, groupIds: [$groupId]) {
            id
            type
            value
            normalizedValue
            period
            context
            excelTag {
              name
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        companyId,
        groupId
      }
    });
    yield put(actions.getUpdatedFundamentalsSuccess(updatedFundamentals as fundamental[], groupId));
  } catch (error) {
    yield put(actions.getUpdatedFundamentalsError());
  }
}

export default function* modelPageSaga() {
  yield takeLatest(actionTypes.GET_SECTORS, getSectorSaga);
  yield takeLatest(actionTypes.GET_MODELS, getModelSaga);
  yield takeLatest(actionTypes.GET_MY_MODELS, getMyModelSaga);
  yield takeLatest(actionTypes.GET_MODELS_FROM_INDUSTRY, getModelFromIndustrySaga);
  yield takeLatest(actionTypes.UNSUBSCRIBE, unSubscribeModelSaga);
  yield takeLatest(actionTypes.GET_UPDATED_FUNDAMENTAL_GROUPS, getUpdatedFundamentalGroupSaga);
  yield takeLatest(actionTypes.GET_UPDATED_FUNDAMENTALS, getUpdatedFundamentalsSaga);
}
