import { ModelType } from 'domain/models';
import { Mixpanel } from 'infra/mixpanel';

export const getFormattedDate = (date: string) => {
  const d = new Date(date);
  return d.toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    hour12: true,
    minute: '2-digit'
  });
};

export const validatedModelDownload = async (
  token: string,
  modelId: string,
  modelType: ModelType,
  ticker?: string
) => {
  const downloadURL = `${process.env.REACT_APP_BACKEND_URL}/excel?x-signed-token=${token}`;
  const res = await fetch(downloadURL, { redirect: 'manual' });
  if (res.ok) {
    Mixpanel.track('marketplace:model:download:success', {
      modelId,
      modelType,
      ticker
    });
    window.location.assign(downloadURL);
  } else {
    Mixpanel.track('marketplace:model:download:failed', {
      modelId,
      modelType,
      ticker
    });
    throw new Error(res.status?.toString());
  }
};
