import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
const actionTypes = {
  GET_SECTORS: 'marketplace/Models/GET_SECTORS',
  GET_SECTORS_PENDING: 'marketplace/Models/GET_SECTORS_PENDING',
  GET_SECTORS_ERROR: 'marketplace/Models/GET_SECTORS_ERROR',
  SET_SECTORS: 'marketplace/Models/SET_SECTORS',
  SET_SELECTED_SECTOR: 'marketplace/Models/SET_SELECTED_SECTOR',

  GET_MODELS: 'marketplace/Models/GET_MODELS',
  GET_MODELS_FROM_INDUSTRY: 'marketplace/Models/GET_MODELS_FROM_INDUSTRY',
  GET_MODELS_FROM_INDUSTRY_PENDING: 'marketplace/Models/GET_MODELS_FROM_INDUSTRY_PENDING',
  SET_MODELS_FROM_INDUSTRY: 'marketplace/Models/SET_MODELS_FROM_INDUSTRY',
  GET_MODELS_PENDING: 'marketplace/Models/GET_MODELS_PENDING',
  GET_MODELS_ERROR: 'marketplace/Models/GET_MODELS_ERROR',
  SET_MODELS: 'marketplace/Models/SET_MODELS',
  UPDATE_MODEL_PENDING: 'marketplace/Models/UPDATE_MODEL_PENDING',
  UPDATE_MODELS: 'marketplace/Models/UPDATE_MODELS',

  GET_MY_MODELS: 'marketplace/Models/GET_MY_MODELS',
  SET_MY_MODELS: 'marketplace/Models/SET_MY_MODELS',
  SUBSCRIPTION_PENDING: 'marketplace/Models/SUBSCRIPTION_PENDING',

  SELECT_COMPANY: 'marketplace/Models/SELECT_COMPANY',

  UNSUBSCRIBE_PENDING: 'marketplace/Models/UNSUBSCRIBE_PENDING',
  UNSUBSCRIBE_SUCCESS: 'marketplace/Models/UNSUBSCRIBE_SUCCESS',
  UNSUBSCRIBE_ERROR: 'marketplace/Models/UNSUBSCRIBE_ERROR',
  UNSUBSCRIBE: 'marketplace/Models/UNSUBSCRIBE',

  ACTIVATE_AND_DOWNLOAD_MODELS: 'marketplace/Models/ACTIVATE_AND_DOWNLOAD_MODELS',
  DOWNLOAD_MODELS: 'marketplace/Models/DOWNLOAD_MODELS',
  DOWNLOAD_MODELS_STATUS: 'marketplace/Models/DOWNLOAD_MODELS',
  DOWNLOAD_MODELS_ERROR: 'marketplace/Models/DOWNLOAD_MODELS_ERROR',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  RESET: 'RESET',

  GET_UPDATE_NOTIFICATIONS: 'marketplace/Models/GET_UPDATE_NOTIFICATIONS',
  GET_UPDATE_NOTIFICATIONS_PENDING: 'marketplace/Models/GET_UPDATE_NOTIFICATIONS_PENDING',
  GET_UPDATE_NOTIFICATIONS_SUCCESS: 'marketplace/Models/GET_UPDATE_NOTIFICATIONS_SUCCESS',
  GET_UPDATE_NOTIFICATIONS_ERROR: 'marketplace/Models/GET_UPDATE_NOTIFICATIONS_ERROR',
  POLL_UPDATE_NOTIFICATIONS: 'marketplace/Models/POLL_UPDATE_NOTIFICATIONS',

  MARK_ALL_NOTIFICATIONS_SEEN: 'marketplace/Models/MARK_ALL_NOTIFICATIONS_SEEN',
  MARK_ALL_NOTIFICATIONS_SEEN_PENDING: 'marketplace/Models/MARK_ALL_NOTIFICATIONS_SEEN_PENDING',
  MARK_ALL_NOTIFICATIONS_SEEN_SUCCESS: 'marketplace/Models/MARK_ALL_NOTIFICATIONS_SEEN_SUCCESS',
  MARK_ALL_NOTIFICATIONS_SEEN_ERROR: 'marketplace/Models/MARK_ALL_NOTIFICATIONS_SEEN_ERROR',

  MARK_COMPANY_NOTIFICATION_SEEN: 'marketplace/Models/MARK_COMPANY_NOTIFICATION_SEEN',
  MARK_COMPANY_NOTIFICATION_SEEN_PENDING:
    'marketplace/Models/MARK_COMPANY_NOTIFICATION_SEEN_PENDING',
  MARK_COMPANY_NOTIFICATION_SEEN_SUCCESS:
    'marketplace/Models/MARK_COMPANY_NOTIFICATION_SEEN_SUCCESS',
  MARK_COMPANY_NOTIFICATION_SEEN_ERROR: 'marketplace/Models/MARK_COMPANY_NOTIFICATION_SEEN_ERROR',

  GET_UPDATED_FUNDAMENTAL_GROUPS: 'marketplace/Models/GET_UPDATED_FUNDAMENTAL_GROUPS',
  GET_UPDATED_FUNDAMENTAL_GROUPS_PENDING:
    'marketplace/Models/GET_UPDATED_FUNDAMENTAL_GROUPS_PENDING',
  GET_UPDATED_FUNDAMENTAL_GROUPS_SUCCESS:
    'marketplace/Models/GET_UPDATED_FUNDAMENTAL_GROUPS_SUCCESS',
  GET_UPDATED_FUNDAMENTAL_GROUPS_ERROR: 'marketplace/Models/GET_UPDATED_FUNDAMENTAL_GROUPS_ERROR',
  SELECT_NOTIFICATION_COMPANY: 'marketplace/Models/SELECT_NOTIFICATION_COMPANY',

  GET_UPDATED_FUNDAMENTALS: 'marketplace/Models/GET_UPDATED_FUNDAMENTALS',
  GET_UPDATED_FUNDAMENTALS_PENDING: 'marketplace/Models/GET_UPDATED_FUNDAMENTALS_PENDING',
  GET_UPDATED_FUNDAMENTALS_SUCCESS: 'marketplace/Models/GET_UPDATED_FUNDAMENTALS_SUCCESS',
  GET_UPDATED_FUNDAMENTALS_ERROR: 'marketplace/Models/GET_UPDATED_FUNDAMENTALS_ERROR',

  GET_EARNING_UPDATE_NOTIFICATION_CONFIG:
    'marketplace/Models/GET_EARNING_UPDATE_NOTIFICATION_CONFIG',
  SET_EARNING_UPDATE_NOTIFICATION_CONFIG:
    'marketplace/Models/SET_EARNING_UPDATE_NOTIFICATION_CONFIG',
  STOP_POLLING: 'STOP_POLLING'
};
export type ModelsProps = {
  width: Breakpoint;
};
export type DownloadModelsProps = {
  isOpened: boolean;
  onClose: () => void;
};
export type sectorList = Array<{
  id: string;
  name: string;
  modelCount: number | null;
  industriesSet: Array<{ id: string; name: string }>;
}> | null;
export type sectorData = {
  sectorList: sectorList;
  totalNumberOfModels: number;
};

export type company = {
  id: string;
  name: string;
  ticker: string;
  disabled?: boolean;
  favicon: string;
  priority?: string;
};

export type clientModels = {
  id: string;
  downloadUrl: string;
  latestQuarter: string;
  isSubscribed: boolean;
  company: company;
};

export type modelObject = {
  industry: {
    id: string;
    name: string;
  };
  numberOfModelsInIndustry: number;
  clientModels: clientModels[];
};

export type ModelsState = {
  //Add reducer state type here
  isSectorLoading: boolean;
  isSectorError: boolean;
  sectorError: string | null;
  sectors: sectorList;
  seletedSectorId: string;
  isModelsLoading: boolean;
  modelsError: string | null;
  isModelsError: boolean;
  modelList: modelObject[] | null;
  isUpdating: boolean;
  totalNumberOfModels: number | null;
  isIndustryModelLoading: number | null;
  totalNumberOfIndustries: number;
  myModel: clientModels[] | null;
  subscriptionPending: string | null;
  stateName: string | null;
  isUnsubscribeLoading: boolean;
  isUnsubscribeError: boolean;
  unsubscrbieError: string | null;
  selectedCompany: company | null;
  unsubscribed: boolean;
  isUpdateNotificationsLoading: boolean;
  companyUpdateNotifications: { [key: string]: updateNotification };
  isUpdatedFundamentalsGroupsLoading: boolean;
  updatedFundamentalGroups: updatedFundamentalGroup;
  notificationCompany: company | null;
  updatedFundamentalsByGroupId: { [key: string]: fundamental[] };
  updatedFundamentalsByGroupIdLoading: { [key: string]: boolean };
  earningUpdateNotificationsConfig: earningUpdateNotificationsConfig | null;
  downloadingModelType: string | null;
  isMarkingAllNotificationsLoading: boolean;
  markingNotificationSeenCompanyId?: string | null;
  downloadingModelId: string | null;
};

/**
 * Sectors
 */

export type getSectorAction = () => {
  type: typeof actionTypes.GET_SECTORS;
};
export type getSectorPendingAction = () => {
  type: typeof actionTypes.GET_SECTORS_PENDING;
};
export type getSectorErrorAction = (
  error: string
) => {
  type: typeof actionTypes.GET_SECTORS_ERROR;
  error: string;
};

export type setSectorAction = (
  sectors: sectorData
) => {
  type: typeof actionTypes.GET_SECTORS;
  sectors: sectorData;
};

export type setSelectedSectorAction = (
  sectorId: string
) => {
  type: typeof actionTypes.SET_SELECTED_SECTOR;
  sectorId: string;
};

/**
 * Model
 */

export type getModelParam = {
  sectorId?: number;
  numberOfIndustries?: number;
  numberOfModelsPerIndustry?: number;
  keyword?: string;
  isUpdate?: boolean;
  pageNumber?: number;
};
export type getModelAction = (
  params: getModelParam
) => {
  type: typeof actionTypes.GET_MODELS;
  params: getModelParam;
};
export type getModelFromIndustryAction = (
  industryId: string,
  indexToInsert: number
) => {
  type: typeof actionTypes.GET_MODELS_FROM_INDUSTRY;
  industryId: number;
  indexToInsert: number;
};

export type getModelFromIndustryPendingAction = (
  industryIdLoadMore: number
) => {
  type: typeof actionTypes.GET_MODELS_FROM_INDUSTRY_PENDING;
  industryIdLoadMore: number;
};

export type setModelFromIndustryAction = (
  models: modelObject[] | null
) => {
  type: typeof actionTypes.SET_MODELS_FROM_INDUSTRY;
  models: modelObject[] | null;
};
export type getModelPendingAction = () => {
  type: typeof actionTypes.GET_MODELS_PENDING;
};
export type updateModelPendingAction = () => {
  type: typeof actionTypes.UPDATE_MODEL_PENDING;
};
export type getModelErrorAction = (
  error: string
) => {
  type: typeof actionTypes.GET_MODELS_ERROR;
  error: string;
};
export type setModelAction = (
  models: modelObject[] | null,
  totalNumberOfIndustries: number
) => {
  type: typeof actionTypes.SET_MODELS;
  models: modelObject[] | null;
  totalNumberOfIndustries: number;
};

export type updateModelAction = (
  models: modelObject[] | null
) => {
  type: typeof actionTypes.UPDATE_MODELS;
  models: modelObject[] | null;
};

export type getMyModelAction = () => {
  type: typeof actionTypes.GET_MY_MODELS;
};
export type setMyModelAction = (
  clientModels: clientModels[] | null
) => {
  type: typeof actionTypes.SET_MY_MODELS;
  clientModels: clientModels[] | null;
};

export type setSubscribeModelPendingAction = (
  modelId: string | null
) => {
  type: typeof actionTypes.SUBSCRIPTION_PENDING;
  modelId: string | null;
};

export type activateAndDownloadModelAction = (
  companyId: string,
  modelId: string,
  modelIndex: number,
  industryIndex: number,
  ticker: string
) => {
  type: typeof actionTypes.ACTIVATE_AND_DOWNLOAD_MODELS;
  payload: {
    companyId: string;
    modelId: string;
    modelIndex: number;
    industryIndex: number;
    ticker: string;
  };
};

export type downloadModelAction = (
  modelId: string,
  modelType: string,
  ticker: string
) => {
  type: typeof actionTypes.ACTIVATE_AND_DOWNLOAD_MODELS;
  payload: { modelId: string; modelType: string; ticker: string };
};

export type downloadModelStatusAction = (
  modelStatus: string,
  modelType: string | null,
  modelId: string | null
) => {
  type: typeof actionTypes.DOWNLOAD_MODELS_STATUS;
  modelId: string | null;
};

export type downloadModelErrorAction = (
  error: string,
  modelStatus: string
) => {
  type: typeof actionTypes.DOWNLOAD_MODELS_ERROR;
};

export type unSubscribePendingAction = () => {
  type: typeof actionTypes.UNSUBSCRIBE_PENDING;
};

export type unSubscribeAction = (
  modelId: string
) => {
  type: typeof actionTypes.UNSUBSCRIBE;
  companyId: string;
};

export type unSubscribeSuccess = () => {
  type: typeof actionTypes.UNSUBSCRIBE_SUCCESS;
};

export type unSubscribeErrorAction = (
  error: string
) => {
  type: typeof actionTypes.UNSUBSCRIBE_ERROR;
  error: string;
};

export type selectCompanyAction = (
  company: company
) => {
  type: typeof actionTypes.SELECT_COMPANY;
  company: company;
};

export type resetStateAction = () => {
  type: typeof actionTypes.RESET;
};

export type actionCreatorTypes = {
  type: string;
  sectors?: sectorData;
  error?: string;
  sectorId?: string;
  models?: modelObject[] | null;
  industryId?: string;
  industryIdLoadMore?: number;
  totalNumberOfIndustries?: number;
  clientModels?: clientModels[];
  modelId?: string | null;
  modelStatus?: string | null;
  stateName?: string | null;
  company?: company | null;
  updateNotifications?: updateNotification[];
  updatedFundamentalGroups?: updatedFundamentalGroup;
  groupId: string;
  updatedFundamentals: fundamental[];
  config: earningUpdateNotificationsConfig | null;
  modelType: string | null;
  companyId?: string;
};

export type notificationDetail = {
  period: string;
  company: company;
  createdAt: string;
};

export enum fundamentalGroupAction {
  PUBLISH = 'PUBLISH',
  UNPUBLISH = 'UNPUBLISH'
}

export type fundamentalGroupActionLog = {
  id: string;
  company: company;
  action: fundamentalGroupAction;
  period: string;
  updatedAt: string;
  count: number;
};

export type updateNotification = {
  notificationCount: string;
  logs: fundamentalGroupActionLog[];
  seen: boolean;
  company: company;
  updatedAt: string;
};

export type markAllNotificationsSeenAction = (
  companyIds: string[]
) => {
  type: typeof actionTypes.MARK_ALL_NOTIFICATIONS_SEEN;
  companyIds: string[];
};

export type markAllNotificationsSeenPendingAction = () => {
  type: typeof actionTypes.MARK_ALL_NOTIFICATIONS_SEEN_PENDING;
};
export type markAllNotificationSeenErrorAction = () => {
  type: typeof actionTypes.MARK_ALL_NOTIFICATIONS_SEEN_ERROR;
};

export type markAllNotificationsSeenSuccessAction = () => {
  type: typeof actionTypes.MARK_ALL_NOTIFICATIONS_SEEN_SUCCESS;
};

//  company notifications
export type markCompanyNotificationSeenAction = (
  companyId: string
) => {
  type: typeof actionTypes.MARK_COMPANY_NOTIFICATION_SEEN;
  companyId: string;
};

export type markCompanyNotificationSeenPendingAction = (
  companyId: string
) => {
  type: typeof actionTypes.MARK_COMPANY_NOTIFICATION_SEEN_PENDING;
  companyId: string;
};
export type markCompanyNotificationSeenSuccessAction = (
  companyId: string
) => {
  type: typeof actionTypes.MARK_COMPANY_NOTIFICATION_SEEN_SUCCESS;
  companyId: string;
};

export type markCompanyNotificationSeenErrorAction = () => {
  type: typeof actionTypes.MARK_COMPANY_NOTIFICATION_SEEN_ERROR;
};

export type fundamentalGroup = {
  id: string;
  label: string;
  company: company;
  createdAt: string;
  updatedAt: string;
  latestPeriod: string;
  earningsUpdatedAt: string;
};

export type document = {
  id: string;
  html: string;
  period: string;
  filingType: string;
  query: string;
  documentType: string;
  documentDescription: string;
  companyId: string;
  dropTime: string;
};

export type updatedFundamentalGroup = {
  fundamentalGroups: fundamentalGroup[];
  document: document | null;
};

export type getUpdatedFundamentalGroupsAction = (
  companyId: string
) => {
  type: typeof actionTypes.GET_UPDATED_FUNDAMENTAL_GROUPS;
  companyId: string;
};

export type getUpdatedFundamentalGroupsSuccessAction = (
  updatedFundamentalGroups: updatedFundamentalGroup
) => {
  type: typeof actionTypes.GET_UPDATED_FUNDAMENTAL_GROUPS_SUCCESS;
  updatedFundamentalGroups: updatedFundamentalGroup;
};

export type getUpdatedFundamentalGroupsPendingAction = () => {
  type: typeof actionTypes.GET_UPDATED_FUNDAMENTAL_GROUPS_PENDING;
};
export type getUpdatedFundamentalGroupsErrorAction = () => {
  type: typeof actionTypes.GET_UPDATED_FUNDAMENTAL_GROUPS_ERROR;
};

export type selectNotificationCompanyAction = (
  company: company | null
) => {
  type: typeof actionTypes.SELECT_NOTIFICATION_COMPANY;
  company: company | null;
};

export type fundamental = {
  context: string;
  value: number;
  period: string;
  id: string;
  type: string;
  excelTag: {
    name: string;
  };
  normalizedValue: number;
};

export type getUpdatedFundamentalsAction = (
  companyId: string,
  groupId: string
) => {
  type: typeof actionTypes.GET_UPDATED_FUNDAMENTALS;
  companyId: string;
  groupId: string;
};

export type getUpdatedFundamentalsSuccessAction = (
  updatedFundamentals: fundamental[],
  groupId: string
) => {
  type: typeof actionTypes.GET_UPDATED_FUNDAMENTALS_SUCCESS;
  updatedFundamentals: fundamental[];
  groupId: string;
};

export type getUpdatedFundamentalsPendingAction = (
  groupId: string
) => {
  type: typeof actionTypes.GET_UPDATED_FUNDAMENTALS_PENDING;
  groupId: string;
};
export type getUpdatedFundamentalsErrorAction = () => {
  type: typeof actionTypes.GET_UPDATED_FUNDAMENTALS_ERROR;
};

export type getEarningUpdateNotificationConfigAction = () => {
  type: typeof actionTypes.GET_EARNING_UPDATE_NOTIFICATION_CONFIG;
};

export type earningUpdateNotificationsConfig = { [env: string]: boolean };

export type setEarningUpdateNotificationConfigAction = (
  config: earningUpdateNotificationsConfig
) => {
  type: typeof actionTypes.SET_EARNING_UPDATE_NOTIFICATION_CONFIG;
  config: earningUpdateNotificationsConfig;
};

export default actionTypes;
