import { GENERAL_REDUCER_ACTIONS, GeneralActionCreator, GeneralReducerFunction } from '../types';

export const setV2BannerClosed: GeneralActionCreator<GENERAL_REDUCER_ACTIONS.SET_V2_BANNER_CLOSED> = () => ({
  type: GENERAL_REDUCER_ACTIONS.SET_V2_BANNER_CLOSED
});

export const setV2BannerClosedReducer: GeneralReducerFunction<GENERAL_REDUCER_ACTIONS.SET_V2_BANNER_CLOSED> = (
  state
) => ({
  ...state,
  v2BannerClosed: true
});
