import { Fundamental } from '../../LiveModelPreview/types';
import Formula from 'fparser';
export const ALPHABETS = [...Array(26).keys()].map((i) =>
  String.fromCharCode(i + 97).toUpperCase()
);

export const ROLLED_UP_NUMBER_FORMULA_REGEX = /C_(?<constant>\d*\.?\d+)+|(?<seriesId>\d+)/gim;

export const getValue = (fundamental: Fundamental, isYTD: boolean) =>
  parseFloat(isYTD ? fundamental?.value ?? 0 : fundamental?.normalizedValue ?? 0);

export const getSeriesIdsFromFormula = (formula: string) => {
  const numbers = [];
  const matches = formula.matchAll(ROLLED_UP_NUMBER_FORMULA_REGEX);
  for (const match of matches) {
    if (match.groups?.seriesId) {
      numbers.push(match.groups.seriesId);
    }
  }
  return numbers;
};

export const formatFormula = (formula: string) => {
  let newFormula = String(formula);
  let replaceIndex = 0;
  newFormula = newFormula.replaceAll(
    ROLLED_UP_NUMBER_FORMULA_REGEX,
    (substring, constant, seriesId) => {
      if (seriesId) {
        replaceIndex += 1;
        return `[${ALPHABETS[replaceIndex - 1]}${substring}]`;
      } else if (constant) {
        return constant;
      }
    }
  );
  return newFormula;
};

export const getFormulaObject = (formulaString: string) => {
  try {
    const formulaObject = new Formula(formulaString);
    return formulaObject;
  } catch {
    return null;
  }
};

export const evaluate = (
  formulaObject: Formula,
  fundamentalsBySeriesId: { [id: string]: Fundamental[] },
  seriesIds: string[],
  isYTD: boolean
) => {
  const vars: { [key: string]: number } = {};
  seriesIds.forEach((seriesId, index) => {
    vars[`${ALPHABETS[index]}${seriesId}`] = getValue(fundamentalsBySeriesId[seriesId][0], isYTD);
  });
  return formulaObject.evaluate(vars);
};
