import { gql } from '@apollo/client';
import { UserSettings } from 'domain/models/user-models';

export const GET_USER_SETTINGS_SERVICE = gql`
  query userSettings {
    userSettings {
      notifications {
        receiveNewClientViewUpdates
        receiveIntraQuarterModificationUpdates
        receiveIntraQuarterNewRowsUpdates
        receiveDocumentIngestedUpdates
        receiveAutotaggingCompletedUpdates
      }
      preferences {
        downloadFormat
      }
    }
  }
`;

export const UPDATE_USER_SETTINGS_SERVICE = gql`
  mutation userSettings(
    $notifications: NotificationsInputType
    $preferences: PreferencesInputType
  ) {
    userSettings(notifications: $notifications, preferences: $preferences) {
      success
      userSettings {
        notifications {
          receiveAutotaggingCompletedUpdates
          receiveDocumentIngestedUpdates
          receiveIntraQuarterModificationUpdates
          receiveIntraQuarterNewRowsUpdates
          receiveNewClientViewUpdates
        }
        preferences {
          downloadFormat
        }
      }
    }
  }
`;

export type UserSettingsResponse = {
  userSettings: UserSettings;
};
