import React from 'react';

import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';

import { SHOW_REQUEST_MODEL } from 'main/constants';
import ModelFilter from 'components/ModelFilter';
import SidebarRqModel from 'components/SidebarRqModel';
import useLocal from 'localization';

import { Badge } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { routes } from 'main/router';
import { useSelectors } from 'presentation/hooks';
import useStyles from './style';
import Props, { allModelId, myModelId } from './types';

const ModelSidebar: React.FC<Props> = ({
  sectorList,
  hasSectorError,
  sectorError,
  isSectorLoading,
  selectFilter,
  selectedSector,
  isFreeTier,
  allowedSectors,
  isEnterpriseTier,
  isTrialTier
}: Props) => {
  const industryTitle = useLocal('industries');
  const subscribed = useLocal('subscribed');
  const allModel = useLocal('all_model');
  const industryModel = useLocal('industry_model');
  const classes = useStyles();

  const { isAuthenticated } = useSelectors.Auth();

  const extraFilter = isAuthenticated
    ? [
        { name: subscribed, id: myModelId, isProtected: true },
        { name: allModel, id: allModelId }
      ]
    : [{ name: allModel, id: allModelId }];
  const newText = useLocal('new');
  return (
    <Box
      position={{ md: 'fixed', lg: 'fixed', xl: 'fixed' }}
      width={{ md: '15vw', lg: '15vw', xl: '20em' }}
      data-testid="sector-list"
    >
      {isSectorLoading && (
        <>
          {[...Array(16)].map((data, index) => (
            <Skeleton key={`sidebar-skeleton-${data}-${index}`} height={33.2} />
          ))}
        </>
      )}
      {hasSectorError && <Alert severity="error">{sectorError}</Alert>}
      {sectorList?.length && (
        <ModelFilter
          title={industryTitle}
          selectFilter={selectFilter}
          seletedFilterId={selectedSector}
          isFreeTier={isFreeTier}
          allowedSectors={allowedSectors}
          showIcon
          list={[...extraFilter, ...sectorList]}
          key="IndustryFilter"
        />
      )}

      {(isTrialTier || isEnterpriseTier) && !isSectorLoading && (
        <Badge
          color={'error'}
          classes={{ root: classes.badgeRoot, anchorOriginTopRightRectangle: classes.newBadge }}
          badgeContent={newText}
          className={classes.industryModelItem}
        >
          <a
            href={routes.INDUSTRY}
            target="_blank"
            rel="noreferrer"
            className={classes.industryModelLink}
          >
            {industryModel}
            <ListItemIcon className={classes.icon}>
              <ArrowRightIcon />
            </ListItemIcon>
          </a>
        </Badge>
      )}

      {SHOW_REQUEST_MODEL && <SidebarRqModel key="SidebarRqModel" />}
    </Box>
  );
};

export default React.memo(ModelSidebar);
