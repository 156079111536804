import { MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
  GET_USER_NOTIFICATIONS_SERVICE,
  MARK_SEEN_NOTIFICATION_SERVICE,
  MarkSeenNotificationService,
  UserNotificationsService
} from 'application/services';

export const useUserServices = () => {
  const useUserNotifications = (options?: QueryHookOptions<UserNotificationsService.Response>) =>
    useQuery<UserNotificationsService.Response>(GET_USER_NOTIFICATIONS_SERVICE, {
      fetchPolicy: 'no-cache',
      ...options
    });

  const useMarkSeenNotifications = (
    options?: MutationHookOptions<
      MarkSeenNotificationService.Response,
      MarkSeenNotificationService.Params
    >
  ) =>
    useMutation<MarkSeenNotificationService.Response, MarkSeenNotificationService.Params>(
      MARK_SEEN_NOTIFICATION_SERVICE,
      {
        ...options,
        refetchQueries: [GET_USER_NOTIFICATIONS_SERVICE]
      }
    );

  return {
    getNotifications: useUserNotifications,
    markSeenNotifications: useMarkSeenNotifications
  };
};
