import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  gap: '1rem',
  flexDirection: 'column'
});

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.up('lg')]: {
    flexWrap: 'nowrap'
  }
}));
