import { gql } from '@apollo/client';

export const EMAIL_VERIFICATION_SERVICE = gql`
  mutation verifyEmailMutation($uid: String!, $token: String!) {
    verifyEmail(uid: $uid, token: $token) {
      success
      errors
    }
  }
`;

export namespace EmailVerificationService {
  export type Params = {
    uid: string;
    token: string;
  };
  export type Response = {
    verifyEmail: {
      success: boolean;
      errors?: string[];
    };
  };
}
