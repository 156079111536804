import React from 'react';
import { Container } from './ModelFormatOption.styles';
import { ModelType } from 'domain/models';

type Props = {
  format: string;
  example: string;
  selected: boolean;
  value: ModelType;
  onClick: (value: ModelType) => void;
};

export const ModelFormatOption = ({ example, format, onClick, selected, value }: Props) => {
  const handleClick = () => {
    onClick(value);
  };
  return (
    <Container selected={selected} onClick={handleClick}>
      <span>{format}</span>
      {example}
    </Container>
  );
};
