import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import useLocal from 'localization';

import { HttpMethod } from 'application/protocols';
import SubHeader from 'components/SubHeader';
import { httpClient } from 'infra/http';
import { Cookies } from 'infra/storage';
import { useAuthServices } from 'presentation/hooks';
import { useLocation, useNavigate } from 'react-router';
import TextFundamentalTypes from './duck';
import useStyles from './style';

const TextFundamental: React.FC<TextFundamentalTypes> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const iframe = useRef<HTMLIFrameElement>(null);
  const tagViewerLocal = useLocal('tag_viewer');
  const [url, setUrl] = useState<string>(`/text_fundamental${location.search}`);
  const [urlObject, setUrlObject] = useState<string>('');

  const accessToken = Cookies.get('accessToken');

  const [logout] = useAuthServices().logout();

  const getTextFundamentalsSrc = useCallback(async () => {
    setUrlObject('');
    const response = await httpClient.request<string>({
      withCredentials: true,
      method: HttpMethod.GET,
      url
    });
    let content = response?.data ?? '';
    if (content) {
      content = content.replaceAll('/static/', `${process.env.REACT_APP_BACKEND_URL}/static/`);
      setUrlObject(content);
    }
  }, [url]);

  useEffect(() => {
    if (!accessToken) {
      logout();
    } else {
      void getTextFundamentalsSrc();
    }
  }, [accessToken, getTextFundamentalsSrc, logout]);

  const onIframeLoad = () => {
    // load prime check from doppler
    if (!iframe.current) {
      return;
    }
    window.onmessage = function (e: MessageEvent) {
      if (e.data.type === 'linkClicked') {
        navigate(`/src/${e.data.id}`);
      } else if (e.data.type === 'paginationLinkClicked') {
        navigate(`/text-fundamental${e.data.link}`);
        setUrl(`/text_fundamental${location.search}`);
      }
    };
    if (iframe.current && iframe.current.contentWindow != null) {
      const iframeWindow = iframe.current.contentWindow as Window & { BACKEND_URL: string };

      iframeWindow.BACKEND_URL = `${process.env.REACT_APP_BACKEND_URL}${url}`;
    }
  };

  return (
    <Box pt={{ xs: 18, sm: 15 }}>
      <SubHeader title={tagViewerLocal} />
      <Container className={classes.container} maxWidth="xl" data-testid="page-container">
        <Box bgcolor="white.main" height="80vh">
          {urlObject && (
            <iframe
              ref={iframe}
              srcDoc={urlObject}
              className={classes.iframe}
              title="tag-viewer"
              onLoad={onIframeLoad}
              src={window.location.href}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default TextFundamental;
