import React, { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { ProviderComposition } from 'main/providers/ProviderComposition';
import { Menu, SearchInput } from 'presentation/components';
import { useSelectors } from 'presentation/hooks';
import { Outlet } from 'react-router';
import { Container } from './DefaultLayout.styles';

type Props = {
  children?: React.ReactNode;
};

export const DefaultLayout = ({ children }: Props) => {
  const { isAuthenticated } = useSelectors.Auth();
  const { menuExpanded } = useSelectors.General();
  const { tier } = useSelectors.User();

  const isLoading = useMemo(() => isAuthenticated && !tier, [isAuthenticated, tier]);

  return (
    <ProviderComposition>
      <Menu />
      <Container menuExpanded={menuExpanded}>
        <SearchInput />
        {isLoading && <CircularProgress size={24} style={{ margin: '0 auto' }} />}
        {!isLoading && (
          <>
            {children}
            <Outlet />
          </>
        )}
      </Container>
    </ProviderComposition>
  );
};
