import {
  Fundamental,
  FundamentalByPeriod,
  FundamentalFilters,
  FundamentalStatus,
  SectionWithSeries,
  Series,
  SeriesWithFundamentalsByPeriod
} from './types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useStyles from './style';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getModelLivePreviewSections, getSeries } from './service';
import { Box, Tooltip } from '@material-ui/core';
import useLocal from '../../localization';
import moment from 'moment';
import { GridCellParams, GridColumns, GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import SearchField from '../../components/SearchField';
import { isEmpty } from 'lodash';
import AppDataGrid from '../../components/AppDataGrid';
import { Link } from '@mui/material';
import { FilingTypeFilters } from './FilingTypeFilters';
import { Mixpanel } from 'infra/mixpanel';
import { cloneDeep } from '@apollo/client/utilities';
import { COLORS_MAP, COMPOSITE, FILLER_VALUE, LESS_THAN_30_MIN_COLOR, TYPE_MAP } from './constants';
import { getTimeFormatted } from 'main/utils';

interface LiveModelPreviewTableProps {
  companyId: string;
}

const PAGE_SIZE = 100;

const formatDateTime = (timeStr: string) => moment(timeStr).format('YYYY-MM-DD, h:mm A zz');

export const LiveModelPreviewTable = ({ companyId }: LiveModelPreviewTableProps) => {
  const classes = useStyles();
  const [filters, setFilters] = useState<FundamentalFilters>({
    query: undefined,
    filingTypes: undefined,
    publishedOnly: false
  });
  const [tempQuery, setTempQuery] = useState<string | undefined>();
  const [sectionId, setSectionId] = useState<string>();
  const [pageNumberBySectionId, setPageNumberBySectionId] = useState<{ [key: string]: number }>({});

  const [allSectionsWithSeriesById, setAllSectionsWithSeriesById] = useState<{
    [id: string]: SectionWithSeries;
  }>({});

  const queryKey = companyId
    ? [
        'modelLivePreview',
        {
          companyId,
          ...filters
        }
      ]
    : [];

  const { isLoading: isSectionsLoading, data: sectionsById } = useQuery(
    queryKey,
    () =>
      companyId
        ? getModelLivePreviewSections(
            companyId,
            filters.query,
            isEmpty(filters.filingTypes) ? undefined : filters.filingTypes,
            filters.publishedOnly
          )
        : undefined,
    {
      enabled: !!companyId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false
    }
  );

  const {
    isLoading: isSectionDetailsLoading,
    data: sectionDetails,
    isFetchingNextPage: isFetchingNextSectionDetails,
    fetchNextPage: fetchNextSectionDetailsPage
  } = useInfiniteQuery(
    sectionId && companyId ? ['sectionDetails', { companyId, sectionId }] : [],
    ({ pageParam = 0 }) =>
      companyId && sectionId && !!sectionsById && !!sectionsById[sectionId]
        ? getSeries(
            companyId,
            sectionsById[sectionId]?.seriesIds.slice(
              parseInt(pageParam) * PAGE_SIZE,
              (parseInt(pageParam) + 1) * PAGE_SIZE
            ),
            parseInt(pageParam)
          )
        : undefined,
    {
      enabled: !!companyId && !!sectionId && !!sectionsById && !!sectionsById[sectionId],
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: false,
      getNextPageParam: (lastPage) => (lastPage?.page ?? 0) + 1,
      getPreviousPageParam: (firstPage, allPages) =>
        allPages ? allPages[allPages.length - 1]?.page : 0
    }
  );

  const searchText = useLocal('search_text');

  const changeFilters = useCallback((filter: Partial<FundamentalFilters>) => {
    setFilters((prevFilter) => ({ ...prevFilter, ...filter }));
    setSectionId(undefined);
    Mixpanel.track('marketplace:preview:filters:change', filter);
  }, []);

  const mainColumns: GridColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Section Name',
        minWidth: window.innerWidth * 0.9,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <div className={classes.section}>
              <div>{(params.row as SectionWithSeries).name}</div>
            </div>
          );
        }
      }
    ],
    [classes.section]
  );
  const [columns, setColumns] = useState(mainColumns);

  useEffect(() => {
    if (sectionsById && !isSectionsLoading) {
      setAllSectionsWithSeriesById(sectionsById);
    }

    if (sectionId && sectionsById && sectionDetails && !isSectionDetailsLoading) {
      const newSectionsWithSeriesById = cloneDeep(allSectionsWithSeriesById);
      const section = sectionsById[sectionId];
      const series: SeriesWithFundamentalsByPeriod[] = [];
      sectionDetails.pages.forEach((page) => {
        page?.data.series.forEach((currentSeries) => {
          const fundamentalsByPeriod: FundamentalByPeriod = currentSeries.fundamentals.reduce(
            (collector, fundamental) => {
              collector[fundamental.period] = fundamental;
              return collector;
            },
            {} as FundamentalByPeriod
          );
          series.push({ ...currentSeries, fundamentals: fundamentalsByPeriod });
        });
      });
      newSectionsWithSeriesById[sectionId] = { ...section, series };
      setAllSectionsWithSeriesById(newSectionsWithSeriesById);
    }
    if (sectionDetails && !isSectionDetailsLoading) {
      let periodColumns: GridColumns = [];
      const periodSet = new Set<string>();
      sectionDetails.pages.forEach((page) => {
        page?.data.periods?.forEach((period) => periodSet.add(period));
      });
      const periods = Array.from(periodSet);
      periods.forEach((period) => {
        const column = {
          field: `fundamentals-${period}`,
          headerName: period,
          width: 180,
          filterable: false,
          sortable: false,
          renderCell: (params: GridCellParams) => {
            const fundamental = params.row.fundamentals[period] as Fundamental;
            if (!fundamental) {
              return <span>-</span>;
            }
            const document = fundamental.document;
            const status = fundamental?.status;
            const type = fundamental?.type;

            let value;
            let title = '';
            let publishedIn;
            let formattedPublishedIn;
            if ([FundamentalStatus.PUBLISHED, FundamentalStatus.CLIENTVIEW].includes(status)) {
              value = parseFloat(fundamental?.normalizedValue ?? fundamental?.value ?? 0).toFixed(
                2
              );
              const documentInjestTime =
                document && ![FILLER_VALUE, COMPOSITE].includes(type)
                  ? formatDateTime(document.createdAt)
                  : `unknown (due to ${TYPE_MAP[type]})`;

              const documentFilingTime = (() => {
                if (!fundamental.document?.filing?.acceptedDate) return '';

                const formatedDate = formatDateTime(fundamental.document.filing.acceptedDate);

                return `Filing dropped at ${formatedDate}\n`;
              })();

              const publishedAt = fundamental?.publishedAt;
              const publishTime = fundamental ? formatDateTime(publishedAt) : 'unknown';
              formattedPublishedIn = fundamental.publishedIn
                ? getTimeFormatted(fundamental.publishedIn)
                : null;
              publishedIn =
                formattedPublishedIn && ![FILLER_VALUE, COMPOSITE].includes(type)
                  ? `${formattedPublishedIn.value} ${formattedPublishedIn.unit}`
                  : `unknown (due to ${TYPE_MAP[type]})`;

              const fundamentalIsPublished = status === FundamentalStatus.PUBLISHED;

              const updateSource = fundamentalIsPublished
                ? 'Incremental Updates'
                : 'regular model upload';
              const updateTime = fundamentalIsPublished
                ? `Data updated at ${publishTime}`
                : `Model updated at ${publishTime}`;

              title = `This data is updated via ${updateSource}\n${documentFilingTime}Ingested at ${documentInjestTime}\n${updateTime}`;
            } else {
              value = status?.replace('_', ' ');
            }

            return (
              <Tooltip classes={{ tooltip: classes.tooltip }} title={title} placement={'top'}>
                <span
                  style={{
                    color: [FundamentalStatus.NOT_RELEASED, FundamentalStatus.PENDING].includes(
                      status
                    )
                      ? COLORS_MAP[status]
                      : formattedPublishedIn?.unit === 'min' &&
                        parseFloat(formattedPublishedIn?.value) <= 30
                      ? LESS_THAN_30_MIN_COLOR
                      : undefined
                  }}
                >
                  {value ?? '-'} {publishedIn ? `(${publishedIn})` : ''}
                </span>
              </Tooltip>
            );
          }
        };
        periodColumns.push(column);
      });
      periodColumns = [
        {
          field: 'name',
          headerName: 'Calendar',
          minWidth: 300,
          filterable: false,
          sortable: false
        },
        {
          field: 'filingType',
          headerName: 'Filing Type',
          type: 'string',
          renderCell: (params) => {
            const document = (params.row as Series).document;
            return !document?.filingType ? (
              '-'
            ) : (
              <Link
                color={'primary'}
                className={classes.documentUrl}
                href={document.url}
                target={'_blank'}
              >
                {document.filingType}
              </Link>
            );
          },
          width: 200,
          filterable: false,
          sortable: false
        },
        ...(periodColumns ?? [])
      ];
      setColumns(periodColumns);
    }
    // Adding the missing prop will make a re-render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sectionsById,
    isSectionsLoading,
    companyId,
    sectionId,
    sectionDetails,
    isSectionDetailsLoading,
    classes.tooltip,
    classes.documentUrl
  ]);

  const filteredAllSectionsWithSeriesById = useMemo(
    () => Object.values(allSectionsWithSeriesById),
    [allSectionsWithSeriesById]
  );

  const onKeyPressed = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        changeFilters({ query: tempQuery });
      }
    },
    [changeFilters, tempQuery]
  );

  const onSearchFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = event.target.value;
      setTempQuery(isEmpty(value) ? undefined : value);
    },
    [setTempQuery]
  );

  const onDetailPanelExpandStateChange = useCallback((ids: GridRowId[] = []) => {
    setSectionId(ids[ids?.length - 1] as string);
    if (ids.length > 0) {
      Mixpanel.track('marketplace:preview:detail_panel:expanded_state:open', { ids });
    } else {
      Mixpanel.track('marketplace:preview:detail_panel:expanded_state:close');
    }
  }, []);

  const onDetailPanelGridPageChange = useCallback(
    (page: number, row: GridRowParams) => {
      Mixpanel.track('marketplace:preview:detail_panel:page_change', { page });
      setPageNumberBySectionId({
        ...pageNumberBySectionId,
        [(row.row as SectionWithSeries).id]: page
      });
      if (page > 0 && !sectionDetails?.pageParams?.includes(page)) {
        void fetchNextSectionDetailsPage({ pageParam: page });
      }
    },
    [setPageNumberBySectionId, sectionDetails, pageNumberBySectionId, fetchNextSectionDetailsPage]
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.filters}>
        <SearchField
          placeholder={searchText}
          variant="outlined"
          onKeyPress={onKeyPressed}
          className={classes.search}
          onChange={onSearchFieldChange}
        />
        <FilingTypeFilters
          onFilter={(selectedFilingTypes) => changeFilters({ filingTypes: selectedFilingTypes })}
        />
      </Box>
      <Box className={classes.tableContainer}>
        <AppDataGrid
          columns={mainColumns}
          rows={filteredAllSectionsWithSeriesById}
          autoHeight={true}
          loading={isSectionsLoading}
          getRowId={(row) => row.id}
          isRowSelectable={() => false}
          filterMode={'server'}
          disableMultipleColumnsFiltering={true}
          onDetailPanelExpandedRowIdsChange={onDetailPanelExpandStateChange}
          detailPanelExpandedRowIds={sectionId ? [sectionId] : []}
          getDetailPanelHeight={() => 'auto'}
          getDetailPanelContent={(row) => (
            <AppDataGrid
              columns={columns}
              rows={(row.row as SectionWithSeries).series}
              getRowId={(series: Series) => series.id}
              rowHeight={40}
              autoHeight={true}
              loading={isFetchingNextSectionDetails || isSectionDetailsLoading}
              pageSize={PAGE_SIZE}
              rowsPerPageOptions={[]}
              onPageChange={(page) => onDetailPanelGridPageChange(page, row)}
              page={pageNumberBySectionId[(row.row as SectionWithSeries).id] ?? 0}
              pagination={true}
              rowCount={(row.row as SectionWithSeries).seriesIds?.length ?? -1}
              key={(row.row as SectionWithSeries).id}
            />
          )}
          rowHeight={70}
        />
      </Box>
    </Box>
  );
};
