import {
  GENERAL_REDUCER_ACTIONS,
  GeneralActionCreator,
  GeneralReducerFunction,
  MENU_STATE_KEY
} from '../types';

export const setMenuState: GeneralActionCreator<GENERAL_REDUCER_ACTIONS.SET_MENU_STATE> = (
  menuExpanded = false
) => {
  localStorage.setItem(MENU_STATE_KEY, menuExpanded.toString());

  return {
    type: GENERAL_REDUCER_ACTIONS.SET_MENU_STATE,
    value: menuExpanded
  };
};

export const setMenuStateReducer: GeneralReducerFunction<GENERAL_REDUCER_ACTIONS.SET_MENU_STATE> = (
  state,
  payload
) => ({
  ...state,
  menuExpanded: !!payload
});
