import { Dialog, styled } from '@mui/material';

export const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',
    minWidth: '371px',
    maxWidth: 'unset',
    padding: '16px',
    margin: 0,
    borderRadius: '10px',
    border: '1px solid #CFD1D1',
    background: '#FFFFFF',

    '& form': {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px'
    },

    '& .MuiCircularProgress-root': {
      color: 'white'
    }
  }
});

export const Header = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  paddingRight: '1.5rem',
  color: '#000000DE',

  '& svg': {
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
    fontSize: '1.5rem',
    color: '#0000008F'
  }
});

export const Separator = styled('div')({
  width: '100%',
  borderTop: '1px solid #E0E0E0'
});
