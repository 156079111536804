import { useMutation } from '@apollo/client';
import {
  MODEL_DOWNLOAD_SERVICE,
  ModelDownloadService,
  USER_DETAILS_SERVICE
} from 'application/services';

export const useGeneralServices = () => {
  const useModelDownload = () =>
    useMutation<ModelDownloadService.Response, ModelDownloadService.Params>(
      MODEL_DOWNLOAD_SERVICE,
      {
        fetchPolicy: 'no-cache',
        refetchQueries: [USER_DETAILS_SERVICE]
      }
    );

  return {
    modelDownload: useModelDownload
  };
};
