import React, { useLayoutEffect } from 'react';
import { useQuery } from '@apollo/client';
import { SESSION_COOKIE_NAME } from 'application/protocols';
import { USER_DETAILS_SERVICE, UserDetailsServiceResponse } from 'application/services';
import { initializeIntercom } from 'infra/intercom';
import { SET_LANGUAGE } from 'localization/reducer';
import { useSelectors } from 'presentation/hooks';
import { onCookieChange } from 'presentation/listeners';
import { useDispatch } from 'react-redux';
import { authenticated, loggedOut, setUserData, userStateCleared } from 'store/slices';

export type Props = {
  children?: React.ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelectors.Auth();
  const { email } = useSelectors.User();

  const { data, refetch } = useQuery<UserDetailsServiceResponse>(USER_DETAILS_SERVICE, {
    skip: !isAuthenticated,
    fetchPolicy: 'no-cache'
  });

  useLayoutEffect(() => {
    dispatch({
      type: SET_LANGUAGE,
      lang: 'en'
    });
  }, [dispatch]);

  useLayoutEffect(() => {
    if (isAuthenticated) {
      if (data) {
        dispatch(setUserData(data.userMarketplaceDetail));
      } else {
        void refetch();
      }
    }
  }, [data, dispatch, isAuthenticated, refetch]);

  useLayoutEffect(() => {
    if (process.env.REACT_APP_ENABLE_INTERCOM === 'true' && email) {
      initializeIntercom({ email: email });
    }
  }, [dispatch, email]);

  useLayoutEffect(() => {
    const listener = onCookieChange(SESSION_COOKIE_NAME, (value) => {
      if (value) {
        dispatch(setUserData({ email: value }));
        dispatch(authenticated());
      } else {
        dispatch(userStateCleared());
        dispatch(loggedOut());
      }
    });

    return () => listener();
  }, [dispatch]);

  return <>{children}</>;
};
