import JSCookie from 'js-cookie';

import { CookieAttributes, CookieStorage, SESSION_COOKIE_NAME } from 'application/protocols';

class Cookie implements CookieStorage {
  get(key: string) {
    return JSCookie.get(key);
  }

  set(key: string, value?: string | Record<string, unknown>, options?: CookieAttributes) {
    if (!value) {
      JSCookie.remove(key, options);
      return;
    }

    JSCookie.set(key, value, options);
  }

  getSessionCookie = () => {
    return JSCookie.get(SESSION_COOKIE_NAME);
  };

  removeSessionCookie = () => {
    const domain = (() => {
      const env = process.env.REACT_APP_ENV;
      if (env === 'development') return 'localhost';
      if (env === 'staging') return '.stag.daloopa.com';

      return '.daloopa.com';
    })();
    JSCookie.remove(SESSION_COOKIE_NAME, { domain });
    JSCookie.remove('accessToken');
  };
}

export const Cookies = new Cookie();
