import { gql } from '@apollo/client';
import { UserData } from 'domain/models';

export const USER_DETAILS_SERVICE = gql`
  query {
    userMarketplaceDetail {
      id
      tier
      userType
      productType
      receiveModelUpdateNotification
      maxSubscriptions
      allowedSectors
      firstName
      lastName
      email
      phoneNumber
      subscribedCount
      downloadFormat
    }
  }
`;

export type UserDetailsServiceResponse = {
  userMarketplaceDetail: UserData;
};
