import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const FeatureFlagValidationProvider = ({ children }: Props) => {
  // const dispatch = useDispatch();

  // const { client } = useStatsigClient();

  // useEffect(() => {
  //   dispatch(setFeatureFlags({  }));
  // }, [dispatch]);

  return <>{children}</>;
};
