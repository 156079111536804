import React from 'react';
import { routes } from '../routes';
import { RouteObject } from 'react-router';
import { ResetPassword, Signup } from 'presentation/pages';
import { VerifyEmail } from 'presentation/pages/verify-email/VerifyEmail';

export const authRoutes: RouteObject[] = [
  {
    path: routes.SIGN_UP,
    element: <Signup />
  },
  {
    path: routes.VERIFY_EMAIL,
    element: <VerifyEmail />
  },
  {
    path: routes.RESET_PASSWORD,
    element: <ResetPassword />
  }
];
