export enum MODEL_STATUS {
  IN_PROGRESS = 'In progress',
  NO_PENDING_UPDATES = 'No pending updates',
  READY_FOR_UPDATE = 'Ready for update'
}

export enum MODEL_SUBSCRIPTION_ERRORS {
  TRIAL_TIER_NOT_ALLOWED = 'This feature isn’t available in your current plan.\n Upgrade to unlock more!',
  FREE_TIER_NOT_ALLOWED = 'This feature isn’t available in your current plan.\n Upgrade to unlock more!',
  STUDENT_TIER_NOT_ALLOWED = 'This feature isn’t available in your current plan.\n Upgrade to unlock more!',
  MAX_SUBSCRIPTIONS_EXCEEDED = 'You’ve reached your subscription limit. Upgrade to unlock more!',
  ACCESS_DENIED = 'This company is not available on the current plan.\n Upgrade to unlock more!'
}

export type IndustryModel = {
  description: string;
  id: string;
  industry: string;
  latestQuarter: string;
};

export type ModelType =
  | 'clientview'
  | 'clientview_with_source_unit_columns'
  | 'clientview_snapshot'
  | 'clientview_snapshot_with_source_unit_columns'
  | 'industry_model';
