import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  listText: {
    paddingLeft: '.5em',
    justifyContent: 'space-between'
  },
  leftArea: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    minWidth: 'auto'
  },
  badgeRoot: {
    width: '100%'
  },
  selectedText: {
    fontWeight: 'bold'
  },
  newBadge: {
    right: '30px !important'
  },
  industryModelLink: {
    display: 'flex',
    width: '100%',
    paddingLeft: '.5em',
    justifyContent: 'space-between',
    textDecoration: 'none',
    color: 'inherit'
  },
  industryModelItem: {
    marginTop: theme.spacing(2)
  }
}));
