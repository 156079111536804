import { Mixpanel } from 'infra/mixpanel';

type Props = {
  email?: string;
};

export const initializeIntercom = ({ email, ...settings }: Props) => {
  if ((window as any)?.Intercom) {
    let intercomSettings: {
      app_id: string;
      email?: string;
      [key: string]: any;
    } = {
      app_id: 'bce2swx3'
    };

    if (email) {
      intercomSettings = {
        ...intercomSettings,
        email
      };
    }

    intercomSettings = {
      ...intercomSettings,
      settings
    };

    (window as any)?.Intercom('boot', intercomSettings);

    const referrer = document ? document.referrer : 'unknown';
    const href = window.location.href;
    const mixpanelDistinctId = Mixpanel.getDistinctId();

    const metadata = {
      href,
      referrer,
      mixpanelDistinctId
    };

    (window as any)?.Intercom('trackEvent', 'initialize', metadata);
  }
};
