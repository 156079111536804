/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { MENU_COLLAPSED_WIDTH, MENU_EXPANDED_WIDTH } from 'main/constants';
import { styled } from '@mui/material';

const NOTIFICATION_CONTAINER_GAP = 16;
const NOTIFICATION_CONTAINER_WIDTH = 500;

export const NotificationBadge = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '8px',
  left: '40px',
  padding: 0,
  width: '10px',
  height: '10px',
  borderRadius: '100%',
  fontSize: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  marginLeft: 'auto',
  background: '#EF5350',
  lineHeight: '20px',
  overflow: 'hidden',

  [theme.breakpoints.up('sm')]: {
    position: 'unset',
    width: 'fit-content',
    height: '20px',
    padding: '0 10px',
    borderRadius: '21px',
    fontSize: '12px'
  }
}));

export const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  height: '423px',
  width: '100%',
  maxWidth: `calc(100% - ${MENU_COLLAPSED_WIDTH + NOTIFICATION_CONTAINER_GAP}px)`,
  background: 'white',
  border: '1px solid #CFD1D1',
  borderRadius: '10px',
  padding: '1rem',
  left: MENU_COLLAPSED_WIDTH + NOTIFICATION_CONTAINER_GAP,
  bottom: '1rem',
  zIndex: 1499,
  transition: 'left 0.13s ease-in-out',

  [theme.breakpoints.up('sm')]: {
    maxWidth: `min(calc(100% - ${
      MENU_EXPANDED_WIDTH + NOTIFICATION_CONTAINER_GAP
    }px), ${NOTIFICATION_CONTAINER_WIDTH}px)`,
    left: MENU_EXPANDED_WIDTH + NOTIFICATION_CONTAINER_GAP
  }
}));

export const Header = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',
  width: '100%',
  paddingBottom: '1rem',
  paddingRight: '2rem',
  fontSize: '0.875rem',
  fontWeight: 500,
  borderBottom: '1px solid #E0E0E0',

  '& > button': {
    gap: '0.5rem',
    alignItems: 'center',
    width: 'fit-content',
    fontSize: '0.75rem',
    color: '#000000DE',

    '& > svg': {
      fontSize: '1.125rem',
      minWidth: '1.125rem'
    }
  },

  '& > svg': {
    position: 'absolute',
    right: 0,
    fontSize: '1.5rem',
    color: '#0000008F',
    cursor: 'pointer'
  },

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',

    '& > button': {
      marginLeft: 'auto'
    }
  }
}));

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  paddingTop: '1rem',
  overflowY: 'auto'
});

export const Notification = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '0.5rem',

  '& > a': {
    position: 'absolute',
    right: 24,
    top: 16,
    textTransform: 'uppercase',
    color: '#263B54',
    fontSize: '0.75rem',
    fontWeight: 500,
    textDecoration: 'none',
    zIndex: 1
  },

  '& > div.badge': {
    position: 'absolute',
    right: 8,
    top: 20,
    width: '6px',
    height: '6px',
    borderRadius: '100%',
    background: '#EF5350'
  }
});
export const CompanyName = styled('div')({
  position: 'relative',
  display: 'block',
  flexDirection: 'column',
  width: '100%',
  paddingRight: '100px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

export const Sections = styled('div')({
  width: '100%',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

export const Timestamp = styled('div')({
  width: '100%',
  color: '#00000099',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});
