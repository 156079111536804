import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '371px',

  '& > .MuiCircularProgress-root.MuiCircularProgress-colorPrimary': {
    color: '#263B54',
    margin: '72px auto'
  },

  '& a': {
    width: '100%',

    '& button': {
      width: '100%'
    }
  }
});

export const Details = styled('span')({
  fontSize: '14px',
  color: '#60646C'
});
