import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Tooltip } from '@mui/material';
import { CloseIcon } from 'presentation/components/Icons';
import { routes } from 'main/router';
import { Container, MessageWrapper } from './ActionBannerV2Notification.styles';
import { setV2BannerClosed } from 'store';
import { useAppSelector } from 'store/hooks';
import { generalState } from 'store/slices';
import { useLocation } from 'react-router';
import { Mixpanel } from 'infra/mixpanel';

export const ActionBannerV2Notification = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { v2BannerClosed } = useAppSelector(generalState);

  const ALLOWED_ROUTES = [routes.HOME, routes.MODELS];
  const hideBanner = !ALLOWED_ROUTES.some((route) => location.pathname === route);

  const handleClick = () => {
    Mixpanel.track('marketplaceV2:action_banner_click:new_marketplace:visit');
  };
  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setV2BannerClosed());
    Mixpanel.track('marketplaceV2:action_banner_click:new_marketplace:close');
  };

  if (v2BannerClosed || hideBanner) return <></>;

  return (
    <Container href={routes.HOME} target="_blank" rel="none" onClick={handleClick}>
      <MessageWrapper>
        <span role="img" aria-label="sparkle-emoji">
          ✨
        </span>
      </MessageWrapper>
      <Tooltip title="Close">
        <Button onClick={handleClose}>
          <CloseIcon />
        </Button>
      </Tooltip>
    </Container>
  );
};
