import { UserSettingsResponse } from 'application/services';

type SettingsContent<T extends string | number> = {
  title: string;
  content: {
    title: string;
    options: {
      title: string;
      description: string;
      dataKey: T;
    }[];
  }[];
}[];

type NotificationsContent = SettingsContent<
  keyof UserSettingsResponse['userSettings']['notifications']
>;

type PreferencesContent = SettingsContent<
  keyof UserSettingsResponse['userSettings']['preferences']
>;

export const settingsPageNotificationsContent: NotificationsContent = [
  {
    title: 'Followed Data Sheet',
    content: [
      {
        title: 'Earnings',
        options: [
          {
            title: 'Tabular datapoints ready',
            description:
              'Get notified after the first wave of tabular datapoints are ready to be updated',
            dataKey: 'receiveAutotaggingCompletedUpdates'
          },
          {
            title: 'Data sheet ready',
            description: 'Get notified when datasheet is fully ready to update',
            dataKey: 'receiveNewClientViewUpdates'
          }
        ]
      },
      {
        title: 'Intra-quarter',
        options: [
          // Backend not ready yet
          // {
          //   title: 'Data sheet modified',
          //   description:
          //     'Get notified when a data sheet is updated, including number revisions or added data.',
          //   dataKey: 'receiveIntraQuarterModificationUpdates'
          // },
          {
            title: 'New data added',
            description: 'Get notified when new data is added to the sheet intra-quarter',
            dataKey: 'receiveIntraQuarterNewRowsUpdates'
          }
        ]
      }
    ]
  }
];
export const settingsPagePreferencesContent: PreferencesContent = [
  {
    title: 'Download',
    content: [
      {
        title: 'File formats',
        options: [
          {
            title: '',
            description:
              'These preferences determine the default format for your downloads from the Marketplace. You can update them anytime',
            dataKey: 'downloadFormat'
          }
        ]
      }
    ]
  }
];
