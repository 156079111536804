import React, { useCallback, useState } from 'react';
import { ModelType } from 'domain/models';
import { ModelFormatOption } from '../ModelFormatOption/ModelFormatOption';
import { Container, Wrapper } from './ModelTypeSelector.styles';
import { Checkbox } from '../Checkbox/Checkbox';

type Props = {
  className?: string;
  value?: ModelType;
  onChange: (value: ModelType) => void;
};

const METADATA_SUFFIX = '_with_source_unit_columns';

export const ModelTypeSelector = ({ className, value, onChange }: Props) => {
  const [type, metadata] = value?.split('_with_') ?? [];

  const [includeMetadata, setIncludeMetadata] = useState(value ? !!metadata : true);

  const getModelType = (modelType: ModelType, includeMetadata: boolean) => {
    if (includeMetadata) {
      return `${modelType}${METADATA_SUFFIX}` as ModelType;
    }
    return modelType;
  };
  const isChecked = (modelType: ModelType) => type === modelType;

  const handleClick = useCallback(
    (modelType: ModelType) => {
      const type = getModelType(modelType, includeMetadata);
      onChange(type);
    },
    [includeMetadata, onChange]
  );

  const handleToogleMetadata = useCallback(() => {
    setIncludeMetadata((prev) => {
      const modelType = getModelType(type as ModelType, !prev);
      onChange(modelType);
      return !prev;
    });
  }, [onChange, type]);

  return (
    <Container className={className}>
      <Wrapper className="options-wrapper">
        <ModelFormatOption
          format="Annuals within Quarters"
          example="QQQQ - A - QQQQ - A"
          onClick={handleClick}
          selected={isChecked('clientview')}
          value="clientview"
        />
        <ModelFormatOption
          format="Annuals outside Quarters"
          example="QQQQ - QQQQ - A  - A"
          onClick={handleClick}
          selected={isChecked('clientview_snapshot')}
          value="clientview_snapshot"
        />
      </Wrapper>
      <Checkbox
        checked={includeMetadata}
        label="Include meta data such as tag ID (Recommended)"
        onClick={handleToogleMetadata}
        disabled={!type}
      />
    </Container>
  );
};
