import React from 'react';
import { CircularProgress } from '@mui/material';
import {
  GET_USER_SETTINGS_SERVICE,
  UPDATE_USER_SETTINGS_SERVICE,
  USER_DETAILS_SERVICE,
  UserSettingsResponse
} from 'application/services';
import {
  Container,
  ContentHeader,
  ContentWrapper,
  OptionDescription,
  OptionTitle,
  OptionWrapper,
  PageHeader,
  SectionContentHeader,
  SectionContentWrapper,
  SectionDescription,
  SectionHeader,
  SectionTitle,
  StyledCheckbox
} from './SettingsPage.styles';
import { settingsPageNotificationsContent, settingsPagePreferencesContent } from './utils';
import { UserSettings } from 'domain/models/user-models';
import { useToastContext } from 'presentation/contexts';
import { Mixpanel } from 'infra/mixpanel';
import { DeepPartial } from 'main/types/utils';
import { useMutation, useQuery } from '@apollo/client';
import { ModelTypeSelector } from 'presentation/components';
import { ModelType } from 'domain/models';

export const SettingsPage = () => {
  const toast = useToastContext();

  const { data, loading } = useQuery<UserSettingsResponse>(GET_USER_SETTINGS_SERVICE, {
    fetchPolicy: 'no-cache'
  });
  const [updateSetting] = useMutation(UPDATE_USER_SETTINGS_SERVICE, {
    refetchQueries: [GET_USER_SETTINGS_SERVICE, USER_DETAILS_SERVICE]
  });

  const { notifications, preferences } = data?.userSettings ?? {};

  const handleUpdateSetting = <
    SectionKey extends keyof UserSettings,
    Key extends Extract<keyof UserSettings[SectionKey], string>
  >(
    sectionKey: SectionKey,
    key: Key
  ) => (value: boolean | ModelType) => {
    const variables: DeepPartial<UserSettings> = {};
    variables[sectionKey] = { [key]: value };

    const eventId = 'marketplaceV2:settings:update';

    let error: string | undefined = undefined;

    void updateSetting({ variables })
      .then(() => {
        toast('Settings updated successfully');
      })
      .catch((e) => {
        error = e.message;
        toast.error();
      })
      .finally(() => {
        Mixpanel.track(eventId, {
          type: sectionKey,
          option: key,
          value,
          error
        });
      });
  };

  if (loading) return <CircularProgress style={{ margin: '0 auto' }} />;

  return (
    <Container>
      <PageHeader>Settings</PageHeader>
      <SectionHeader>
        <SectionTitle>Notifications</SectionTitle>
        <SectionDescription>Manage the types of notifications you receive.</SectionDescription>
      </SectionHeader>
      {settingsPageNotificationsContent.map(({ title, content }, i) => (
        <SectionContentWrapper key={i}>
          <SectionContentHeader>{title}</SectionContentHeader>
          {content.map((c, j) => (
            <ContentWrapper key={j}>
              <ContentHeader>{c.title}</ContentHeader>
              {c.options.map((opt, k) => (
                <OptionWrapper key={k}>
                  <OptionTitle>{opt.title}</OptionTitle>
                  <OptionDescription>{opt.description}</OptionDescription>
                  <StyledCheckbox
                    color="default"
                    checked={notifications?.[opt.dataKey]}
                    onChange={(_, value) =>
                      handleUpdateSetting('notifications', opt.dataKey)(value)
                    }
                  />
                </OptionWrapper>
              ))}
            </ContentWrapper>
          ))}
        </SectionContentWrapper>
      ))}
      <SectionHeader>
        <SectionTitle>Preferences</SectionTitle>
        <SectionDescription>Manage the setting of Marketplace</SectionDescription>
      </SectionHeader>
      {settingsPagePreferencesContent.map(({ title, content }, i) => (
        <SectionContentWrapper key={i}>
          <SectionContentHeader>{title}</SectionContentHeader>
          {content.map((c, j) => (
            <ContentWrapper key={j}>
              <ContentHeader>{c.title}</ContentHeader>
              {c.options.map((opt, k) => (
                <OptionWrapper key={k}>
                  <OptionTitle>{opt.title}</OptionTitle>
                  <OptionDescription>{opt.description}</OptionDescription>
                  {opt.dataKey === 'downloadFormat' && (
                    <ModelTypeSelector
                      value={preferences?.[opt.dataKey]}
                      onChange={handleUpdateSetting('preferences', opt.dataKey)}
                    />
                  )}
                </OptionWrapper>
              ))}
            </ContentWrapper>
          ))}
        </SectionContentWrapper>
      ))}
    </Container>
  );
};

export default SettingsPage;
