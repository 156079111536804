import { GENERAL_REDUCER_ACTIONS, GeneralActionCreator, GeneralReducerFunction } from '../types';

export const setDownloadModelId: GeneralActionCreator<GENERAL_REDUCER_ACTIONS.SET_DOWNLOAD_MODEL_ID> = (
  modelId
) => ({
  type: GENERAL_REDUCER_ACTIONS.SET_DOWNLOAD_MODEL_ID,
  value: modelId
});

export const setDownloadModelIdReducer: GeneralReducerFunction<GENERAL_REDUCER_ACTIONS.SET_DOWNLOAD_MODEL_ID> = (
  state,
  payload
) => ({
  ...state,
  downloadModelId: payload ?? ''
});
