import { gql } from '@apollo/client';

export const MODEL_DOWNLOAD_SERVICE = gql`
  mutation fetchModelDownloadToken($modelId: String!, $modelType: String!) {
    generateModelDownloadToken(modelId: $modelId, modelType: $modelType) {
      token
    }
  }
`;

export namespace ModelDownloadService {
  export type Params = {
    modelId: string;
    modelType: string;
  };

  export type Response = {
    generateModelDownloadToken: {
      token: string;
    };
  };
}
