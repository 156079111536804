import { CircularProgress } from '@mui/material';
import { userIPValidationService } from 'application/services/user/user-ip-validation';
import { DIALOG_IDS } from 'domain/models';
import { useSelectors } from 'presentation/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { toggleDialog } from 'store/slices';

type Props = {
  children: React.ReactNode;
  redirectToV1?: boolean;
};

export const ProtectedRoute = ({ children, redirectToV1 }: Props) => {
  const dispatch = useDispatch();

  const { pathname, search } = useLocation();

  const { isAuthenticated } = useSelectors.Auth();

  if (redirectToV1) {
    const searchParams = new URLSearchParams(search);
    searchParams.set('v1', 'true');

    window.location.replace(`${pathname}?${searchParams.toString()}`);
    return <></>;
  }

  if (!isAuthenticated) {
    dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.LOGIN }));
    return <></>;
  }

  return <>{children}</>;
};

type WhitelistedRouteProps = {
  from: string;
  element: JSX.Element;
};

const WhitelistedRoute = ({ element, from }: WhitelistedRouteProps) => {
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelectors.Auth();

  const [isLoading, setIsLoading] = useState(true);
  const [whitelisted, setWhitelisted] = useState(false);

  useEffect(() => {
    void userIPValidationService.execute().then((res) => {
      if (res.isError()) {
        dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.LOGIN }));
      } else {
        setIsLoading(false);
        setWhitelisted(true);
      }
    });
  }, [from, dispatch]);

  if (isLoading) return <CircularProgress sx={{ margin: '24px auto' }} />;

  if (!isAuthenticated && !whitelisted) return <></>;

  return <>{element}</>;
};

const LoginPlaceholderRoute = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.LOGIN }));
  }, [dispatch]);

  return <></>;
};

type GetProtectedRouteProps = {
  element: JSX.Element;
  ipWhitelisted?: boolean;
  isAuthenticated: boolean;
};

export const getProtectedRoute = ({
  element,
  isAuthenticated,
  ipWhitelisted
}: GetProtectedRouteProps) => {
  const from = window.location.pathname.concat(window.location.search);

  if (!isAuthenticated) {
    if (ipWhitelisted) {
      return <WhitelistedRoute element={element} from={from} />;
    }

    return <LoginPlaceholderRoute />;
  }

  return element;

  return <></>;
};
