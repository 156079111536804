import React, { Fragment, useEffect, useMemo } from 'react';

import {
  ActionButton,
  Container,
  Header,
  HeaderLabel,
  ItemCount,
  StyledTableBody,
  StyledTableHead,
  TableWrapper
} from './IndustryPage.styles';
import { useToastContext } from 'presentation/contexts';
import { useQuery } from '@apollo/client';
import {
  LIST_INDUSTRY_MODELS_SERVICE,
  ListIndustryModelsServiceResponse
} from 'application/services';
import { CircularProgress, Table, TableCell, TableRow } from '@mui/material';
import { IndustryModel } from 'domain/models';
import { DownloadIcon, Tooltip } from 'presentation/components';
import { Mixpanel } from 'infra/mixpanel';
import { Navigate } from 'react-router';
import { routes } from 'main/router';
import { useGeneralServices, useSelectors } from 'presentation/hooks';
import { validatedModelDownload } from 'presentation/utils';

export const IndustryPage = () => {
  const toast = useToastContext();

  const {
    loading,
    error,
    data = { liveIndustryModels: [] },
    refetch
  } = useQuery<ListIndustryModelsServiceResponse>(LIST_INDUSTRY_MODELS_SERVICE);

  const [downloadModel, { loading: downloadingModel }] = useGeneralServices().modelDownload();

  const { isEnterpriseTier, isTrialTier } = useSelectors.User();

  const isEnterprise = isEnterpriseTier || isTrialTier;

  const totalModels = data.liveIndustryModels.length;

  const tableData = useMemo(() => {
    if (loading || error) return {};

    return data.liveIndustryModels.reduce<{ [key: string]: IndustryModel[] }>((acc, curr) => {
      if (!acc[curr.industry]) {
        acc[curr.industry] = [];
      }

      acc[curr.industry].push(curr);
      return acc;
    }, {});
  }, [data.liveIndustryModels, error, loading]);

  const handleDownload = ({ description, id, industry }: IndustryModel) => () => {
    Mixpanel.track('marketplace:industry-model:download', {
      modelId: id,
      type: 'industry_model',
      modelType: 'industry_model',
      ticker: industry,
      description
    });
    downloadModel({ variables: { modelId: id, modelType: 'industry_model' } })
      .then(({ data }) => {
        const downloadToken = data?.generateModelDownloadToken?.token ?? '';

        if (!downloadToken) {
          throw new Error('Failed to generate download token');
        }

        return validatedModelDownload(downloadToken, id, 'industry_model', industry);
      })
      .catch((e) => {
        console.error('[Marketplace] Download Industry Model Error:', e);
        toast.error('Failed to download industry model. Please try again.');
      });
  };

  useEffect(() => {
    if (error) {
      toast.error('Failed to load Industry Models.');
    }
  }, [error, refetch, toast]);

  if (!isEnterprise) return <Navigate to={routes.HOME} replace />;

  return (
    <Container>
      <Header>
        <HeaderLabel>Industry Models</HeaderLabel>
        <ItemCount>
          {totalModels} of {totalModels}
        </ItemCount>
      </Header>
      <TableWrapper>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell className="model-name">Industry Model Name</TableCell>
              <TableCell>Updated Through</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </StyledTableHead>
          {!loading && (
            <StyledTableBody>
              {Object.keys(tableData).map((industry, i) => (
                <Fragment key={i}>
                  <TableRow className="industry">
                    <TableCell>{industry}</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                  {tableData[industry].map((model) => (
                    <TableRow key={model.id}>
                      <TableCell className="model-description">
                        <Tooltip title={model.description} placement="top-start">
                          <div>{model.description}</div>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <div>{model.latestQuarter}</div>
                      </TableCell>
                      <TableCell className="actions">
                        <Tooltip title="Download">
                          <ActionButton onClick={handleDownload(model)} disabled={downloadingModel}>
                            <DownloadIcon />
                            Download
                          </ActionButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              ))}
            </StyledTableBody>
          )}
        </Table>
      </TableWrapper>
      {loading && <CircularProgress size={24} style={{ margin: '24px auto' }} />}
    </Container>
  );
};

export default IndustryPage;
