import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/logo.svg';
import Menu from 'components/Menu';

import styled from '@emotion/styled';
import { InfoOutlined } from '@material-ui/icons';
import { Tooltip } from '@mui/material';
import { ActionBannerV2Notification } from 'components/ActionBannerV2Notification';
import { routes } from 'main/router';
import { useSelectors } from 'presentation/hooks';
import useStyles from './style';

const PlusLogo = styled.p`
  font-family: 'Comfortaa', sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  margin: 0;
  margin-left: 5px;
  font-size: 27px;
  color: black;
`;

const PlusLabel = styled.p`
  font-family: 'Comfortaa', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  width: max-content;
  margin: 0;
  margin-left: 5px;
  margin-top: 6px;
  font-size: 14px;
  color: #ddd;
`;

const Header = () => {
  const classes = useStyles();

  const { productType } = useSelectors.User();

  const isExcelPluginSrc =
    window.location.search?.includes('?excel_plugin=true') &&
    window.location.pathname?.includes('/src/');

  return (
    <>
      {!isExcelPluginSrc && (
        <>
          <Box position="fixed" width="100vw" zIndex={9} top={0}>
            <ActionBannerV2Notification />
            <AppBar position="static">
              <Container maxWidth="xl">
                <Box display="flex">
                  <Box display="flex" flexGrow={1}>
                    <Link className={classes.logoLink} to={routes.HOME}>
                      <SvgIcon className={classes.logo} viewBox="" component={Logo} />
                      {(productType === 'daloopa_plus' ||
                        productType === 'daloopa_plus_time_ltd') && <PlusLogo>plus</PlusLogo>}
                      {productType === 'daloopa_plus_time_ltd' && (
                        <>
                          <PlusLabel>limited time upgrade</PlusLabel>
                          <Tooltip title="To learn more, please reach out to us at sales@daloopa.com">
                            <InfoOutlined style={{ fontSize: '14px', marginLeft: '3px' }} />
                          </Tooltip>
                        </>
                      )}
                    </Link>
                  </Box>
                  <Box py={0}>
                    <Menu />
                  </Box>
                </Box>
              </Container>
            </AppBar>
          </Box>
        </>
      )}
    </>
  );
};

export default React.memo(Header);
