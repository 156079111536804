import { gql } from '@apollo/client';

export const GET_USER_NOTIFICATIONS_SERVICE = gql`
  query earningUpdateNotificationQuery {
    earningUpdateNotifications(logsCount: 5) {
      notificationCount
      updatedAt
      seen
      company {
        id
        name
        ticker
      }
    }
  }
`;

export namespace UserNotificationsService {
  export type Params = void;

  type Notification = {
    notificationCount: number;
    updatedAt: string;
    seen: boolean;
    company: {
      id: number;
      name: string;
      ticker: string;
    };
  };

  export type Response = {
    earningUpdateNotifications: Notification[];
  };
}

export const MARK_SEEN_NOTIFICATION_SERVICE = gql`
  mutation markEarningsUpdateUserNotification($companyIds: [String]!) {
    markEarningsUpdateUserNotification(companyIds: $companyIds, seen: true) {
      success
    }
  }
`;

export namespace MarkSeenNotificationService {
  export type Params = {
    companyIds: string[];
  };

  export type Response = {
    markEarningsUpdateUserNotification: {
      success: boolean;
    };
  };
}
