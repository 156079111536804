import React, { useEffect, useMemo } from 'react';
import { Container, Details } from './EmailVerifcationDialog.styles';
import { Button, Dialog } from 'presentation/components';
import { DIALOG_IDS } from 'domain/models';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { toggleDialog } from 'store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'main/router';
import { useAuthServices } from 'presentation/hooks';
import { VerificationSucceededImage } from './VerificationSucceededImage';
import { VerificationFailedImage } from './VerificationFailedImage';

export const EmailVerificationDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const [emailVerification, { loading, error, data }] = useAuthServices().emailVerification();

  const { success, errors } = data?.verifyEmail ?? {};

  const { token, uid } = useMemo(
    () => ({
      uid: params.get('uid') ?? '',
      token: params.get('token') ?? ''
    }),
    [params]
  );

  const hasError = error || errors;

  const dialogTitle = useMemo(() => {
    if (success) {
      return 'Email Verified Successfully';
    }

    if (hasError) {
      return 'Verification Failed';
    }

    return 'Email Verification';
  }, [success, hasError]);

  const handleClose = () => {
    navigate(routes.HOME);
  };

  const handleClick = () => {
    handleClose();
    dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.LOGIN }));
  };

  useEffect(() => {
    if (token && uid) {
      void emailVerification({ variables: { uid, token } });
    }
  }, [emailVerification, token, uid]);

  return (
    <Dialog id={DIALOG_IDS.VERIFY_EMAIL} onClose={handleClose} header={dialogTitle}>
      <Container>
        {loading && <CircularProgress size={72} />}
        {success && <VerificationSucceededImage />}
        {hasError && <VerificationFailedImage />}
        <Details>
          {success &&
            'Your email has been verified. You can now log in and start using your account.'}
          {hasError &&
            'Please check your inbox for the code and try again. If you didn’t receive the code, request a new one.'}
        </Details>
        {!loading && (
          <>
            {success && (
              <Button variant="contained" size="large" onClick={handleClick}>
                Log in
              </Button>
            )}
            {hasError && (
              <a href="https://daloopa.com/create-an-account" target="_blank" rel="noreferrer">
                <Button variant="contained" size="large">
                  Request verification again
                </Button>
              </a>
            )}
          </>
        )}
      </Container>
    </Dialog>
  );
};
