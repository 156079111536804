import { styled } from '@mui/material';

export const Container = styled('div')<{ selected: boolean }>(({ selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  width: '100%',
  minWidth: '278px',
  padding: '1rem',
  border: '1px solid #9E9E9E',
  borderRadius: '0.25rem',
  background: '#FFFFFF',
  cursor: 'pointer',
  color: '#00000099',
  fontSize: '0.875rem',

  '&:hover': {
    outline: '2px solid #263B54'
  },

  ...(selected && {
    outline: '2px solid #263B54',
    background: '#0074E808'
  }),

  '& > span': {
    fontSize: '1rem',
    fontWeight: 700,
    color: '#000000'
  }
}));
