import { useMutation } from '@apollo/client';
import {
  EMAIL_VERIFICATION_SERVICE,
  EmailVerificationService,
  LOGIN_SERVICE,
  LoginService,
  LOGOUT_SERVICE,
  LogoutService,
  RESET_PASSWORD_SERVICE,
  ResetPasswordService,
  SIGN_UP_SERVICE,
  SignUpService,
  UPDATE_PASSWORD_SERVICE,
  UpdatePasswordService,
  USER_DETAILS_SERVICE
} from 'application/services';
import { Cookies } from 'infra/storage';
import { useDispatch } from 'react-redux';
import { loggedOut } from 'store';
import { userStateCleared } from 'store/slices';

export const useAuthServices = () => {
  const dispatch = useDispatch();

  const useLogin = () =>
    useMutation<LoginService.Response, LoginService.Params>(LOGIN_SERVICE, {
      refetchQueries: [USER_DETAILS_SERVICE],
      onCompleted: ({ tokenAuth }) => {
        if (tokenAuth.token) {
          Cookies.set('accessToken', tokenAuth.token, {
            // 7 days in milliseconds
            expires: 7
          });
        }
      }
    });

  const useEmailVerification = () =>
    useMutation<EmailVerificationService.Response, EmailVerificationService.Params>(
      EMAIL_VERIFICATION_SERVICE
    );

  const useLogout = () =>
    useMutation<LogoutService.Response, LogoutService.Params>(LOGOUT_SERVICE, {
      onCompleted: () => {
        Cookies.removeSessionCookie();
        dispatch(userStateCleared());
        dispatch(loggedOut());
      }
    });

  const useResetPassword = () =>
    useMutation<ResetPasswordService.Response, ResetPasswordService.Params>(RESET_PASSWORD_SERVICE);

  const useSignUp = () =>
    useMutation<SignUpService.Response, SignUpService.Params>(SIGN_UP_SERVICE);

  const useUpdatePassword = () =>
    useMutation<UpdatePasswordService.Response, UpdatePasswordService.Params>(
      UPDATE_PASSWORD_SERVICE
    );

  return {
    emailVerification: useEmailVerification,
    login: useLogin,
    logout: useLogout,
    resetPassword: useResetPassword,
    signup: useSignUp,
    updatePassword: useUpdatePassword
  };
};
